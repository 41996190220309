<template>
    <Home />
</template>
<script>
import Home from '@/layouts/Home'
export default {
    components: {
        Home
    },
    data() {
        return {
            msg: 'Admin基础'
        }
    },
    // 初始化加载
    created: function() {
        // console.log('当前版本：', process.env.NODE_ENV)
        // console.log('判断是否有登录信息')
        // this.checkLogin()
    },
    methods: {
        checkLogin() {
            if (!this.$store.state.user || !this.$store.state.user.token) {
                this.$router.replace('/')
            }
        }
    }
}
</script>
