let menu = [
    {
        id: 'x-it',
        icon: 'el-icon-s-help',
        menu_name: 'IT管理',
        url: '',
        childlist: [
            { id: 'x-it-1', icon: '', menu_name: 'oss系统菜单', url: '/itset/osscontrol' },
            { id: 'x-it-2', icon: '', menu_name: '商户系统菜单', url: '/itset/companycontrol' },
            { id: 'x-it-3', icon: '', menu_name: '客户端API', url: '/itset/clientapi' },
            { id: 'x-it-4', icon: '', menu_name: '教练端API', url: '/itset/coachapi' },
            { id: 'x-it-5', icon: '', menu_name: '商户端小程序API', url: '/itset/wxmerchant' },
            { id: 'x-it-6', icon: '', menu_name: '图片素材', url: '/itset/materialimg' },
        ]
    },
    {
        id: 'x-template',
        icon: 'el-icon-s-platform',
        menu_name: '开发模板',
        url: '',
        childlist: [
            { id: 'x-template-1', icon: '', menu_name: '列表', url: '/template/list' },
            { id: 'x-template-2', icon: '', menu_name: '表单', url: '/template/form' },
            { id: 'x-template-3', icon: '', menu_name: '测试', url: '/template/test' },
            { id: 'x-template-x', icon: '', menu_name: '测试2', url: '/test' },
            { id: 'x-template-4', icon: '', menu_name: '数据统计图', url: '/template/echarts' },
            { id: 'x-template-5', icon: '', menu_name: '截图', url: '/template/cropperImage' },
            { id: 'x-template-6', icon: '', menu_name: '页面', url: '/template/tailoring' },
        ]
    }
]

export default menu
