<template>
    <div>
        <el-row style="height: 50px; color: #fff">
            <el-col v-if="isCollapse" :span="24" class="logo-x" style="color: #fff; width: 65px">练</el-col>
            <el-col v-else :span="24" class="logo-x" style="color: #fff">练市集管理系统</el-col>
        </el-row>
        <el-menu
                :default-active="is_menu"
                background-color="#263238"
                active-text-color="#409EFF"
                text-color="#fff"
                :collapse="isCollapse"
                :router="true"
                :unique-opened="true"
                @select="on_menu"
        >
            <template v-for="val in menu_data">
                <!-- 二级菜单 -->
                <el-submenu v-if="val.childlist != null && val.childlist.length > 0" :key="val.id" :index="val.id">
                    <template slot="title">
                        <i :class="val.icon"></i>
                        <span slot="title">{{ val.menu_name }}</span>
                    </template>
                    <!--子菜单-->
                    <template>
                        <el-menu-item class="er-menu2" v-for="val2 in val.childlist" :index="val2.id" :key="val2.id"
                                      :route="val2.url">{{
                                val2.menu_name
                            }}
                        </el-menu-item>
                    </template>
                </el-submenu>
                <!-- 一级菜单 -->
                <el-menu-item v-else :key="val.id" :index="val.id" :route="val.url">
                    <i :class="val.icon"></i>
                    <span slot="title">{{ val.menu_name }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import menu from '@/../config/menu'

export default {
    data() {
        return {
            menu_data: [],  // 菜单信息
            is_menu: ''     // 当前选中菜单
        }
    },
    // 父组件参数
    props: ['isCollapse'],
    // 初始化加载
    created: function () {
        // 获取菜单
        this.get_menu()
    },
    methods: {
        // 获取菜单
        get_menu() {
            // 选中的菜单
            this.$data.is_menu = this.Tool.get_s_cache('is_menu')

            // 用户信息
            let userinfo = this.Tool.get_l_cache('userinfo')
            // 用户菜单
            let usermenu = this.Tool.get_l_cache('usermenu')

            // console.log('userinfo', userinfo)
            // 总管理员
            if (userinfo !== null && userinfo.is_sysadmin === 1) {
                let usermenuall = usermenu.concat(menu)
                this.$data.menu_data = usermenuall
            } else {
                this.$data.menu_data = usermenu
            }
        },
        on_menu(key) {
            this.Tool.set_s_cache('is_menu', key)
        },
    }
}
</script>

<style scoped>
body {
    color: #fff;
}

.logo-x {
    width: 100%;
    line-height: 50px;
    text-align: center;
}

>>> .el-menu .el-submenu .el-submenu__title {
    height: 50px !important;
    line-height: 50px !important;
}

>>> .el-menu .er-menu2 {
    /*font-size: 12px;*/
    padding-left: 60px !important;
    height: 40px !important;
    line-height: 40px !important;
}

.el-menu--vertical .er-menu2 {
    height: 40px !important;
    line-height: 40px !important;
}

.el-menu {
    border: 0px;
}
</style>
